import { React, useRef, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import GetSchoolList from "./find_school_list";
import classes from "./sign_up.module.css";
import style from "./sign_up.module.css";
import { AuthContext } from "../context/AuthContext";
import Header from "../Header";

const SignUp = (props) => {
  //const [email, setEmail] = useState('');
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [school, setSchool] = useState(103);
  const [schoolList, setSchoolList] = useState("");
  const [schoolArray, setSchoolArray] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // redirect to login:
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);

  const [passwordErrorFlag, setPasswordErrorFlag] = useState(false);
  const [confirmPasswordErrorFlag, setConfirmPasswordErrorFlag] =
    useState(false);
  const [usernameErrorFlag, setUsernameErrorFlag] = useState(false);

  const errors = [
    "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
    "Passwords don't match!",
  ];

  var allowSubmit = false;

  useEffect(() => {
    const fetchData = async () => {
      const schoolList = await GetSchoolList();
      //TODO: schooList here (id, school_name)pair, set each school setSchooList
      setSchoolList(schoolList);
      if (schoolList) {
        console.log("has schooList");
      } else {
        console.log("schoolList null");
      }
    };
    fetchData();
  }, []);

  let { email1 } = useParams();
  let { verification_code1 } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      username: username,
      password: password,
      school: Number(school),
      email: email1,
      verification_code: verification_code1,
    });
  
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/register`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
  
    let isVerificationCodeCorrect = true; // 添加一个变量来跟踪验证码是否正确
  
    axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    
    if (response.data.error) {
      // 如果返回的数据中包含 error 字段,说明注册失败
      alert(response.data.error);
    } else {
      // 如果返回的数据中不包含 error 字段,说明注册成功
      const userid = response.data.userid;
      const session = response.data.session;
      const school_abbr = response.data.school_abbr;
      setAuth({ userid, session, school_abbr });
      setUsername("");
      setPassword("");
      alert("Sign up successful!");
      navigate("/");
    }
  })
  .catch(function (error) {
    console.log("Error during sign up:", error.response ? error.response.data : error.message);
    alert("Sign up failed!");
  });
};

  const isValidPassword = (password) => {
    const backendRegexForPassword = new RegExp(
      /^([0-9a-zA-Z]|[-!@#$%^&*]){6,20}$/gm
    );
    if (backendRegexForPassword.test(password)) {
      setPassword(password);
      setPasswordErrorFlag(false);
      console.log("password error flag:", passwordErrorFlag);
      return false;
    }
    //alert("password is too short; need 6-20 length, allow upper & lower case letters; can have -!@#$%^&*");
    setPasswordErrorFlag(true);
    setPassword(password);
    return true;
  };

  const isValidUsername = (username) => {
    const backendRegexForUsername = new RegExp(/^([a-zA-Z0-9]|[_]){4,16}$/gm);
    if (backendRegexForUsername.test(username)) {
      setUsername(username);
      setUsernameErrorFlag(false);
      console.log("username error flag:", usernameErrorFlag);
      return true;
    }
    setUsernameErrorFlag(true);
    setUsername(username);
    return false;
  };

  const isValidConfirmPassword = (confirmPassword) => {
    if (confirmPassword === password) {
      setConfirmPasswordErrorFlag(false);
      setConfirmPassword(confirmPassword);
      console.log("confirmPassword error flag:", confirmPasswordErrorFlag);
      return false;
    }
    setConfirmPassword(confirmPassword);
    setConfirmPasswordErrorFlag(true);
    return true;
  };

  const isValidSchool = (schoolID) => {
    if (schoolID) {
      setSchool(parseInt(schoolID));
    }
  };

  return (
    <div className={style.container}>
      <div className={style.card}>
        <h1>Account Signup</h1>
        <form className={style.form1} onSubmit={handleSubmit}>
          <div>
            <label className={style.label1}>Email </label>
            <input type="text" value={email1} readonly required />
          </div>
          <div>
            <label className={style.label1}>Verification Code </label>
            <input type="text" value={verification_code1} readonly required />
          </div>
          <div>
            <label className={style.label1}>Username </label>
            <input
              type="text"
              value={username}
              onChange={(e) => isValidUsername(e.target.value)}
              required
            />
            <div>{usernameErrorFlag ? "username error" : null}</div>
          </div>
          <div>
            <label className={style.label1}>Password </label>
            <input
              type="password"
              value={password}
              onChange={(e) => isValidPassword(e.target.value)}
              required
            />
            <div>{passwordErrorFlag ? "password error" : null}</div>
          </div>
          <div>
            <label className={style.label1}>Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => isValidConfirmPassword(e.target.value)}
              required
            />
            <div
              style={{ display: confirmPasswordErrorFlag ? "block" : "none" }}
            >
              {errors[1]}
            </div>
          </div>
          <div>
            <label className={style.label1}>School </label>
            <select
              defaultValue="103"
              className={style.select1}
              onChange={(e) => isValidSchool(e.target.value)}
            >
              <option value="1">
                University of Illinois at Urbana-Champaign
              </option>
              <option selected value="103">
                New York University
              </option>
              <option value="15">University of Southern California</option>
              <option value="2">Purdue University</option>
              <option value="109">University of Oregon</option>
              <option value="110">Barnard University</option>
              <option value="83">Oregon State University</option>
              <option value="55">University of Maryland</option>
            </select>
          </div>
          <div className={style.button2}>
            <button className={allowSubmit ? "disabled" : style.button1}>Register</button>
          </div>
        </form>
      </div>
    </div>
  );
};


export default SignUp;
