import axios from 'axios';
import { useRef, useState, useEffect, useContext } from 'react';
import { AuthContext } from "../context/AuthContext";
import Header from "../Header";
import { useParams } from "react-router-dom";
//import classes from './login.module.css';
//import './Forum.css'; 
            
const Forum = () => {
  const { schoolId } = useParams();
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [forums, setForums] = useState([]);
  const [userid, setUserId] = useState('');
  const [session, setSession] = useState('');
  const [ftid, setftid] = useState('');
  const [category, setCategory] = useState(''); // Define category state
  const [title, setHeader] = useState(''); // Define title state
  const [content, setContent] = useState(''); // Define content state  var axios = require('axios');
  var axios = require('axios');
  useEffect(() => {
      userRef.current.focus();

  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/forum/list_topic?school_id=${schoolId}`);
          setForums(response.data);
        } catch (error) {
          console.log(error);
          setErrMsg('Error fetching forums');
        }
      };

      fetchData();
  }, [schoolId])

  const handleForumSubmit = async (e) => {
       e.preventDefault();
       var id;
       if (category === "category1") {
	 id = 15;
	}
       else if (category === "category2") {
	 id = 16;
	}
	else {
	 id = 17;
	}
console.log(id, userid, session);
var data = {
      userid: userid,
      session: session,
      title: title,
      forum_id: id
    };
// console.log(data);

var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/forum/set_topic`,
      data: data
    };
try {
    const response = await axios(config);
    console.log(JSON.stringify(response.data));
    setftid(response.data.ftid);

    data = {
      userid: userid,
      session: session,
      ftid: response.data.ftid,
      content: content,
    };
    config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/forum/set_post`,
      data: data,
    };
    const secondResponse = await axios(config);
    console.log(JSON.stringify(secondResponse.data));
    //window.alert("The post has been added successfully!");
    window.alert("Post Added successfully!");
  } catch (error) {
    console.log(error);
  }

}


  const handleSubmit = async (e) => {
      e.preventDefault();
          
      var data = JSON.stringify({
        "username": user,
        "password": pwd,
      });

      var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}api/user/login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };


      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        const userid = response.data.userid;
        const session = response.data.session;
        const school_abbr = response.data.school_abbr;
        setAuth({userid, session, school_abbr});
        setUser('');
        setPwd('');
	setUserId(userid);
	setSession(session);
        setSuccess(true);
        })
      .catch(function (error) {
        console.log(error);
        if (!error?.response) {
          setErrMsg('No Server Response');
        } else if (error.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (error.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
        errRef.current.focus();
      });
    }



return (
        <>
	    <Header />
            {success || userid ? (
                <section>
                    <br />
                    <p>
                        <a href="#">Return Home</a>
                    </p>
		<div className="form-container">
      <form onSubmit={handleForumSubmit}>
        <label>Category:</label>
        <select id="category" value={category} onChange={e => setCategory(e.target.value)}>
          <option value="category1">Immigration</option>
          <option value="category2">Job Seeking</option>
          <option value="category3">Credit Card/Sign-up Bonus</option>
          <option value="category4">Girlfriend/Boyfriend/Marriage</option>
          <option value="category5">Individual School</option>
        </select>

        <label>Title:</label>
        <input type="text" id="title" value={title} onChange={e => setHeader(e.target.value)} />

        <label htmlFor="content">Content:</label>
        <textarea id="content" value={content} onChange={e => setContent(e.target.value)} />

        <button type="submit">Post</button>
      </form>
		</div>
    <hr className="subforum-devider" />
    <div className="container">
        <div className="subforum">
            <div classclassName="subforum-title">
                <h1>All Posts</h1>
            </div>
            <div className="subforum-row">
                <div className="subforum-icon subforum-column center">
                    <i className="fa fa-car"></i>
                </div>
                <div className="subforum-description subforum-column">
                <h1><a href="">First Post:</a></h1>
                <h2>Description Content: my car buying experience</h2>
                <p></p>
                </div>
                <div className="subforum-stats subforum-column center">
                    <span><b>11</b> Posts | <b>111</b> Topics</span>
                </div>
                <div className="subforum-info subforum-column">
                <a href="">Last Post</a>on <small>Jun 22 2024</small> by <a href="">Auser</a>
                    
                </div>
        </div>
    </div> 
    <div class="forum-info">
        <div>
            <b>Statistics &nbsp; <i class="fa fa-bar-chart"></i></b>
        </div>
        <div>
            <span /><b><u>50</u></b> Posts in <b><u>100</u></b> Topics by <b><u>100</u></b> Users
            <span /> Latest Post: <b><a href="#">Random Post</a></b>on July 1st 2024 by <b><a href="#">RandomUser</a></b>
        </div>
    </div>
    </div>
                </section>
            ) : (
                <div id = "login_content">
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Sign In</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            type="password" 
                            id="password"
                            onChange={(e) => setPwd(e.target.value)} 
                            value={pwd}
                            required
                        />
                        <button>Sign In</button>
                    </form>
                    <p className="line">
                        Need an Account?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="/verifyemail">Register</a>
                        </span>
                    </p>
                </div>
            )}
        </>
    )
}

export default Forum;

