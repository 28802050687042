import axios from "axios";
import { useState, useEffect } from "react";
import ItemCard from "../item_cards/Item_card";
import CardGroup from "react-bootstrap/CardGroup";
import { useParams, useNavigate } from "react-router-dom";
import style from "./search.module.css";


/*
This will show the details side by side on medium+ screens, 
and stack vertically on small screens.

In ./src/components/search/Search.js :

Use the Col and CardGroup components from React Bootstrap to 
layout the search results in a responsive grid system.
*/

const Search = () => {
  
  const [returnDataItems, setReturnDataItems] = useState([]);
  const [returnDataItemsUrl, setReturnDataItemsUrl] = useState([]);

  var [pageOffset, setPageOffset] = useState(0);
  const [url, setUrl] = useState("");
  let { type, tag } = useParams();
  let offset = useParams().offset || 0;
  const navigate = useNavigate();


  const updateRoutePath = (newType, newOffset) => {
    navigate("/search/" + newType + "/" + newOffset.toString());
  };

  offset = pageOffset !== 0 ? pageOffset : offset;

  useEffect(() => {

    if(tag != undefined) {
      tag = "&tag=" + tag;
    }
    else {
      tag = "";
    }

    setUrl(
      `${process.env.REACT_APP_API_URL}api/item/list?limit=30&offset=` +
      offset +
      "&school_id=1&sort=time_desc&" +
      "type=" +
      type +
      tag
    );

    console.log(type); // Output: "used"
    console.log(tag);  // Output: "books"

    const urlValue = url;
    const config = {
      method: "get",
      url: urlValue,
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    };

    async function getDataItems() {
      const response = await axios(config)
        .then(function (response) {
          return response.data.items;
        })
        .catch(function (error) {
          console.log(error);
        });
      setReturnDataItems(response);
      var dataItems = [];
      for (let i = 0; i < response.length; i++) {
        var src = "https://oss.umiuni.com/" + response[i].photo;
        dataItems.push(src);
      }
      setReturnDataItemsUrl(dataItems);
    }

    getDataItems();

  }, [url]);

  const detailNavigation = (iid) => {
    return "/detail/" + iid;
  };

  const cardGroup = () => {
    
    if(returnDataItems) {
      return returnDataItems.map((currentItem, i) => (
        <ItemCard
          item = {currentItem}
          imageUrl = {returnDataItemsUrl[i]}
          onClick = {() =>
            window.location.href = detailNavigation(currentItem.iid)
          }
        />
      ));
    }

    else {
      return null;
    }
  };


  const changeOffset = (change) => {
    if (isNaN(change)) {
      return;
    }
    const newOffset = change * 30 + parseInt(offset, 10);
    if (newOffset < 0) {
      setPageOffset(0);
      return;
    } else {
      setPageOffset(newOffset);
    }
    const newUrl =
      `${process.env.REACT_APP_API_URL}api/item/list?limit=31&offset=${newOffset}&school_id=1&sort=time_desc&type=${type}`;
    setUrl(newUrl);
    const newType = type;
    updateRoutePath(newType, newOffset);
  };

  return (
    <>
      <div className={style.background}>
        
        <div className={style.searchContainer}>
          <a href="javascript:history.back()">
            Back
          </a>
          <CardGroup>{cardGroup()}</CardGroup>
        </div>
        <div className={style.buttonContainer}>
          <button className={style.buttonLeft} onClick={() => changeOffset(-1)}>
            {" "}
            Last Page
          </button>
          <button className={style.buttonRight} onClick={() => changeOffset(1)}>
            {" "}
            Next Page
          </button>
        </div>
      </div>
    </>
  );
};

export default Search;
