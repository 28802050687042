import classes from './Header.module.css';
import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from './context/AuthContext';
import HeaderDropdown from './header/Header_dropdown';
import HeaderRegister from './header/Header_register';
import HeaderSearchbar from './header/Header_searchbar';
import umiunilogo from '../umiunilogo.jpg'
import { useNavigate } from 'react-router-dom';
import HeaderForumButton from './header/Header_forumbutton';
import DonateForumButton from './header/Header_donatebutton';
import HeaderSchoolDropdown from './header/Header_schooldropdown';
import HeaderChatRoomButton from './header/Header_chatroombutton';
import { IoIosMenu } from 'react-icons/io';

const Header = (props) => {
    const { auth } = useContext(AuthContext);

    const [isMobile, setIsMobile] = useState(false);
    const [secondHeader, setSecondHeader] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();   

    const handleHomePage = () => {
        console.log("awfawef");
        navigate('/');
    }
    const handleChat = () => {
        navigate('/randomchatroom');
    }
    const handleForum = () => {
        navigate('/forumlist/16/15');
    }
    const handleDonate = () => {
        navigate('/donate/payment');
    }
    
    useEffect(() => {
        const checkWindowSize = () => {
            setIsMobile(window.innerWidth <= 768);
            setSecondHeader(window.innerWidth <= 1138);
        };
        checkWindowSize();
        window.addEventListener("resize", checkWindowSize);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener("resize", checkWindowSize);
            window.removeEventListener('click', handleClickOutside);
        };
    }, [])

    const toggleMenuDropdown = () => {
        setShowDropdown(!showDropdown);
        
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };


    return (
        <Fragment>
            {!isMobile ? (
                <header className={classes.header} ref={dropdownRef}>
                <div>
                    <img src = {umiunilogo} className = {classes.logo} onClick = {handleHomePage} />
                </div>
                <div className = {classes.searchbar}>
                    <HeaderSearchbar />
                </div>
                <HeaderSchoolDropdown />

                {!secondHeader? (
                    <>
                        <div className={classes.headerButton}>
                            <HeaderChatRoomButton />
                            <HeaderForumButton />
                            <DonateForumButton />
                        </div>
                        <div className = {classes.user}>
                            {auth.userid ? <HeaderDropdown /> : <HeaderRegister />}
                        </div>
                    </>
                ) : (
                    <>
                        <div className = {classes.user}>
                            {auth.userid ? <HeaderDropdown /> : <HeaderRegister />}
                        </div>
                        <div className={classes.headerButton}>
                                <IoIosMenu onClick={toggleMenuDropdown} />
                                {showDropdown && (
                                    <ul className={classes.dropdown}>
                                        <li onClick={() => {handleChat(); toggleMenuDropdown();}}>ChatRoom</li>
                                        <li onClick={() => {handleForum(); toggleMenuDropdown();}}>Forum</li>
                                        <li onClick={() => {handleDonate(); toggleMenuDropdown();}}>Donate</li>
                                    </ul>
                                )}
                        </div>
                    </>
                )}              
                </header>
                
            ) : (
                <header className={classes.header}>
                    <div>
                        <img src={umiunilogo} className={classes.logo} onClick={handleHomePage} />
                    </div>
                    <div className={classes.schooldropdown}>
                        <HeaderSchoolDropdown />
                    </div>
                </header>
            )}
        </Fragment>
    );

};

export default Header;
