import React from "react";

import Header from "../Header";

import "./FAQ.css";

const FAQ = () => {
  return (
    <div>
      <div className="container">
        <h1>常见问题</h1>
        <ul>
          <li>
            <p class="question">1. 什么是UmiUni?</p>
            <p class="answer">
              答：UmiUni.com是由北美大学校友们创办，为北美大学校友们服务的生活信息平台。北美大学校友可以进行二手闲置买卖，发布公寓转租信息，个人汽车转卖信息，查看周边餐馆，求职信息等。UMIUNI北美校友圈以学校圈和地理圈为单位，方便用户之间直接进行对话，简化了交易流程。
            </p>
          </li>
          <li>
            <p class="question">2. UmiUni是收费平台还是免费平台？</p>
            <p class="answer">
              答：UmiUni是一个免费信息平台，对所有使用者免费开放，不收取任何费用。
            </p>
          </li>
          <li>
            <p class="question">3. UmiUni注册要求？</p>
            <p class="answer">
              答：使用人身份必须是北美校友，并且需要有.edu / .edu.cn
              的学校官方电子邮箱才可以认证学校圈。除了认证商家之外，所以的用户都是北美校友，所以希望大家放心使用。
            </p>
          </li>
          <li>
            <p class="question">4. 卖家群体由谁组成？</p>
            <p class="answer">
              答：卖家群里主要有两部分组成：北美校友群体；认证商家。如果商品标有“认证商家”字符，此商品即为UmiUni认证商家所属。如果没有此标示，即为所在学校的学生所属。
            </p>
          </li>
          <li>
            <p class="question">5. 怎么在UmiUni开始卖东西？</p>
            <p class="answer">
              答：注册个人账号，选择自己所属校园，进入主页后，点击主页右上角“发布商品”即可填写商品信息，上传商品照片，然后发布商品。
            </p>
          </li>
          <li>
            <p class="question">6. 怎么在UmiUni开始买东西？</p>
            <p class="answer">
              答：进入UmiUni.com主页之后，选择自己所属校园，搜索查找自己所需要的商品，然后就可以直接与卖家联系进行交易。
            </p>
          </li>
          <li>
            <p class="question">7. 如何与卖家进行二手闲置交易？</p>
            <p class="answer">
              答：UmiUni是一个共同校园的信息平台，买家进入UmiUni.COM之后需要选择自己所在的学校位置，搜索查找自己所需要的二手闲置商品，点击商品界面中的“联系方式”即可获得卖家自己提供的联系方式。由于所有买家卖家都属于同一个校园群体，所以可以很方:
              便进行商品交易。一般的交易方式由买家和卖家双方自行决定（一般交易方式都有：买家上门取货；卖家送货；约定校园特定位置进行交易等）
            </p>
          </li>
          <li>
            <p class="question">8. 不是大学学生可以进行注册吗？</p>
            <p class="answer">
              答：不可以。所有卖家（除认证商家）必须是学生身份，并且需要有学校官方电子邮件，即以
              @myuniversity.edu 结尾的邮箱。
            </p>
          </li>
          <li>
            <p class="question">
              9. 社区大学学生，没有.edu / .edu.cn的邮箱怎么注册？
            </p>
            <p class="answer">
              答：需要联系我们的客服info@umiuni.com进行学生身份验证。这里可能需要其所在学校的学生卡。
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
