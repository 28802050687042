import axios from 'axios';
import { useRef, useState, useEffect, useContext } from 'react';
import { AuthContext } from "../context/AuthContext";
import Header from "../Header";
import { useParams } from "react-router-dom";
//import classes from './login.module.css';
import ForumListCard from './ForumListCard';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ForumList = () => {
  const { schoolId } = useParams();
  // const { topic } = useParams();
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [forums, setForums] = useState([]);

  const [category, setCategory] = useState(''); // Define category state
  const [title, setHeader] = useState(''); // Define title state
  const [content, setContent] = useState(''); // Define content state  var axios = require('axios');
  const [topic, setTopic] = useState('');
  const navigate = useNavigate();

var axios = require('axios');

  useEffect(() => {
      setErrMsg('');
  }, [user, pwd])

  useEffect(() => {
      const fetchData = async () => {
        try {
	if(topic === '' || topic === undefined || topic === null){
	const response = await axios.get(`${process.env.REACT_APP_API_URL}api/forum/list_topic?offset=0&limit=21&sort=time_desc&school_id=${schoolId}`);
	const responseData = response.data.topics;
  	const response2 = await axios.get(`${process.env.REACT_APP_API_URL}api/forum/list_topic?offset=0&limit=21&sort=time_desc&school_id=0`);
	const responseData2 = response2.data.topics.length > 0 ? response2.data.topics : response2.data.items;
  	const combinedTopics = [...responseData2, ...responseData];
  	const dataArray = Array.isArray(combinedTopics) ? combinedTopics : Array.from(combinedTopics);
        setForums(dataArray);
	}else{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/forum/list_topic?offset=0&limit=21&sort=time_desc&forum_id=${topic}&school_id=${schoolId}`);
	const responseData = response.data.topics;
	const response2 = await axios.get(`${process.env.REACT_APP_API_URL}api/forum/list_topic?offset=0&limit=21&sort=time_desc&school_id=0`);
	const responseData2 = response2.data.topics.length > 0 ? response2.data.topics : response2.data.items;
	const combinedTopics = [...responseData2, ...responseData];
	const dataArray = Array.isArray(combinedTopics) ? combinedTopics : Array.from(combinedTopics);

        setForums(dataArray);
	}
        } catch (error) {
          console.log(error);
          setErrMsg('Error fetching forums');
        }
      };

      fetchData();
  }, [schoolId, topic])


   const handlePost = () => {
        
        let url = "/forum/list";
        navigate(url);
    }
    return (
        <>
	    <Header />
<br /><br /><br /><br />
<select value={topic} onChange={(e) => setTopic(e.target.value)}>
  <option value="">Default</option>
<option value="15">Life</option>
  <option value="16">Other</option>
<option value="17">Study</option>
</select>
                    <br /><br /><br /><br />
<div>
<button onClick = {handlePost}>Post Forum</button>
</div>
<br /><br /><br /><br />

<div>
      {forums.map((forum) => (
	<Link key={forum.id} to={`/forumdetail/${forum.ftid}`} style={{ textDecoration: 'none' }}>
          <ForumListCard key={forum.id} forum={forum} />
        </Link>
      ))}
    </div>
		    <div>
		    </div>
        </>
    )
}

export default ForumList;

