import axios from 'axios';
import { useRef, useState, useEffect, useContext } from 'react';
import style from './user_profile.module.css';
import { AuthContext } from "../context/AuthContext";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import GetSchoolList from '../register/find_school_list';
import lrz from "lrz";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [newName, setNewName] = useState("");

  //school search states
  const [schoolList, setSchoolList] = useState("");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [schoolIndex, setSchoolIndex] = useState();

  const [profilePicture, setProfilePicture] = useState(null);
  const fileInputRef = useRef();
  const asyncWait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const { auth } = useContext(AuthContext);
  const axios = require('axios');

  useEffect(() => {


/*
    const fetchData = async() => {
      const schoolList = await GetSchoolList();
      //TODO: schooList here (id, school_name)pair, set each school setSchooList
      setSchoolList(schoolList.school);
    };
    fetchData();
*/

const fetchData = async () => {
  const schoolList = await GetSchoolList();
  const schoolNames = new Set();

  const hotSchools = Object.values(schoolList).reduce((acc, state) => {
    state.forEach(school => {
      // Filter out schools with hot > 0
      if (school.hot > 0 && !schoolNames.has(school.name)) {
        schoolNames.add(school.name);
        acc.push({ id: school.id, name: school.name });
      }
    });
    return acc;
  }, []);
// Set hotSchools list
  setSchoolList(hotSchools);
   // Console log the hotSchools list
  console.log("Hot Schools List:", hotSchools);
};
fetchData();
  
    
    axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
      params: {
        "userid": auth.userid
      }
    })
    .then((response) => {
      setUserData(response.data);
      setNewName(response.data.username);
      setQuery(response.data.school);
    })
    .catch((error) => {
      console.log(error);
    })

  }, []);

  const displayEmail = () => {
    if(userData != null) {
      return userData.email;
    }
  }

  useEffect(() => {
    if(userData) {
      setProfilePicture(userData.avatar);
    }
  }, [userData]);

  const retAvatar = () => {
    if(userData != null) {
      return userData.avatar;
    }
  }

  const handleClick = async () => {

    console.log(profilePicture);
    
    const findSchoolId = schoolList.filter((jsonObj) => jsonObj.name.includes(query))

    await fetch(`${process.env.REACT_APP_API_URL}api/user/set`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userid: auth.userid,
	      session: auth.session,
	      school: findSchoolId[0].id,
        avatar: profilePicture
      })
    })
    .then(response => response.json())
    .then((response) => {
      console.log(JSON.stringify(response));
    })
    .catch((error) => {
      console.error(error);
    })

    window.location.reload();
      
  }

  const handleInputChange = (event) => {
    const input = event.target.value;
    setQuery(input);

    const filteredResults = schoolList.filter((school) =>
      school.name.toLowerCase().includes(input.toLowerCase())
    )

    setResults(filteredResults);
  }
  

  const handleResultClick = (index) => {
    const selectedSchool = results[index];
    setQuery(selectedSchool.name);
    setSchoolIndex(selectedSchool.id);
    setSelectedResult(null);
    setDisplaySearch(false);
  }

  const handleFocus = () => {
    setDisplaySearch(true);
  }

  const handleProfilePictureClick = () => {
    fileInputRef.current.click();
  }

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];

    lrz(file, { width: 1024 })
    .then(({ file }) => {
      // Pass the processed file to the upload function
      uploadFile(file);
      setProfilePicture(file);
    })
    .catch((error) => {
      console.error("Error processing file:", error);
    });

    setProfilePicture(file);
    console.log(file);
  }

  const uploadFile = (file) => {
    // console.log("132");
    const params = {
      "userid": auth.userid,
      "session": auth.session,
      "file": file
    };
    return axios
      .post(`${process.env.REACT_APP_API_URL}api/file/upload`, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {

        if (res.data.error) {
          if (res.data.error.includes("Authority Failed")) {
            // props.$Notice.error({
            //   title: 'Error:用户登录Session过期',
            //   desc: '5秒后跳转重新登录界面。请登出后重新登录' + extractErrorMessage(res),
            //   duration: 5000,
            // });
            console.log("Error:用户登录Session过期", res);

            (async () => {
              await asyncWait(5000);
              // console.log('two');
              window.location.href = "/user/logout";
            })();
          } else {
            console.log("图片格式不兼容", res);
          }
        } else {
          setProfilePicture(res.data.key);
        }
        // console.log("after uploadFile");
      })
      .catch((res) => {
        // props.$Message.error(res.data.error);
        console.log("response error:", res);
      })
  };

  if(!auth.userid) {
    return(
      <>
        <div className = {style.title}>Not Logged In</div>
      </>
    )
  }
  
  return (
    <>
      <div className = {style.userProfilePage}>
        <div className = {style.profileTop}> 
        <h1>Username:</h1>
          <input 
            spellCheck = "false"
            value = {newName}
            onChange = {(e) => setNewName(e.target.value)}
            className = {style.title}
          />
        </div>
        <Col>
        <Row>
          <div style = {{ width: '5%' }}></div>
          <div style = {{ width: '50%' }}>
            <Col>
                <div className = {style.subtitle}>User ID</div>
                <p className = {style.subcontent}>{ auth.userid }</p>
                <div className = {style.subtitle}>Email</div>
                <p className = {style.subcontent}>{displayEmail()}</p>
                <div className = {style.subtitle}>School</div>
                <input
                  type = "text"
                  value = {query}
                  onChange = {handleInputChange}
                  onFocus = {handleFocus}
                  onClick = {handleInputChange}
                  className = {style.searchBar}
                />
                {results.length > 0 && displaySearch && (		 
                  <div className = {style.searchResultContainer}>
                    {results.map((school, index) => (
                      <div 
                        className = {style.searchResults}
                        key = {index}
                        onClick = {handleResultClick.bind(null, index)}
                      >
                        {school.name}
                      </div>
                    ))}
                  </div>
                )}
              <p></p>
            </Col>
          </div>
          <input
            type = "file"
            accept = "image/*"
            style = {{display: "none"}}
            ref = {fileInputRef}
            onChange = {handleProfilePictureChange}
          />
          <div className = {style.imageContainer} style = {{ width: '20%' }}>
            <p className = {style.subtitle}>Profile Picture</p>
            <div className = {style.avatarContainer}>
              <img 
                src = {`https://oss.umiuni.com/${profilePicture}`}
                className = {style.avatar}
                onClick = {handleProfilePictureClick}
              />
              <div className={style.overlay}></div>
            </div>
          </div>
        </Row>
        </Col>
        <button className = {style.updateButton} onClick = {handleClick}>Update</button>
      </div>
    </>
  );
}

export default UserProfile;

