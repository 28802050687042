// // ListChat.js
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { AuthContext } from "../../context/AuthContext";
// import {useContext} from 'react';
// import ChatEntry from '../ChatEntry';
// import Chatbox from '../Chatbox';
import styles from './listChat.module.css';
// import { useNavigate } from "react-router-dom";

// const ListChat = () => {
//   const [chats, setChats] = useState([]);
//   const { auth } = useContext(AuthContext);
//   console.log("Inside ListChat, before parsingDate");
//   const [messages, setMessages] = useState({}); // Define messages state
//   const [newChatUserIDs, setNewChatUserIDs] = useState(["6877", "6866", "6864", "6880", "55", "6850", "6879","3455", "6883", "6884","6869"]);
//   const [newChatUsernames, setNewChatUsernames] = useState([
//     "UmiUni_AI_GLM4",
//     "Paper_AI",
//     "UmiUni_Chat_Human",
//     "Akoasm/Baron",
//     "UmiUni_Helper",
//     "umiuni_nicholas",
//     "Boyu",
//     "Dean",
//     "XixiangLiu",
//     "Chaoran_Chat",
//     "Helen_Chat"
//   ]);
//   const [newEncodedID, setNewencodedID] = useState([
//     "9OjoQeZV9vepZVg8",
//     "oYq9wdL8VgdjPXgE",
//     "k61YQdJq9DbOGpJZ",
//     "qPGELe32xrd698jZ",
//     "pPy7LDdwpRe1YKXA",
//     "ABWJxboQlzegwOL0",
//     "z4yJrb2vwJbWLDX7",
//     "Py7LDdwpWwe1YKXA",
//     "VqQK9b6RA7bEvYnG",
//     "mQO5xe7LBwa7rJX0",
//     "0Gz3YaOy0gdxqlW1"
//   ]);

//   const navigate = useNavigate();
//   const axios = require('axios');

//   const handleSendMessage = async (userID, content) => {
//     const encoded_userid_url = "https://us.umiuni.com:5002/api/user/encode_userid";
//     const request_body = {
//       userid: userID,
//     };

//     try {
//       // Wait for the response from the axios post request
//       const response = await axios.post(encoded_userid_url, request_body);
  
//       console.log(response.data.result); // Log the response data
  
//       if (response.status === 200) {
//         sendMessage(response.data.result, content);
//       } else {
//         throw new Error('An error occurred when trying to encode the userid');
//       }
//     } catch (error) {
//       throw error;
//     }
//   };

//   const sendMessage = (userID, content) => {
//     let data = JSON.stringify({
//       "userid": auth.userid,
//       "session": auth.session,
//       "to_userid": userID,
//       "content": content 
//     });

//     let config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}api/message/send`,
//       headers: {
//         'Content-Type': 'application/json; charset=utf-8'
//       },
//       data: data,
//     };

//     axios.request(config)
//     .then((response) => {
//       console.log("Message sent to user " + userID);
//       setMessages({ ...messages, [userID]: "" }); // Clear the message input after sending
//     })
//     .catch((error) => {
//       console.log(error);
//     });
//   };

//   const parsingDate = (timestamp) => {
//     // Create a new Date object with the provided timestamp
//     const date = new Date(timestamp);

//     // Extract the components of the date in the local timezone
//     const year = date.getFullYear();
//     const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
//     const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
//     const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
//     const minutes =  date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
//     const seconds = date.getSeconds();

//     return `${year}-${month}-${day} ${hours}:${minutes}`
//   }

  

//   useEffect(() => {


//     /*
//     let data = JSON.stringify({
//       "userid": auth.userid,
//       "session": auth.session
//     });
//     console.log("Inside useEffect():");
//     console.log("Inside useEffect():, userid:", auth.userid);
//     console.log("Inside useEffect():, session:", auth.session);

//     let config = {
//       method: 'post',
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_URL}api/message/list`,
//       headers: { 
//         'Content-Type': 'application/json'
//       },
//       data: data,
//     };
//     axios.request(config)
//     .then((response) => {
//       let chats = []
//       let chatLength = (response.data).length
//       for (let i = 0; i < chatLength; i++) {
//         let userID = (response.data[i]).userid
//         let data = JSON.stringify({
//           "userid": auth.userid,
//           "session": auth.session,
//           "to_userid": userID,
//           "limit": 1
//         });
//         let config = {
//           method: 'post',
//           maxBodyLength: Infinity,
//           url: `${process.env.REACT_APP_API_URL}api/message/get`,
//           headers: { 
//             'Content-Type': 'application/json'
//           },
//           data: data,
//         };

//         axios.request(config)
//         .then((response) => {
//           let lastMessage = (response.data[0]).content
//           let lastMessageTime = (response.data[0]).send_timestamp
//           axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
//             params: {
//               "userid": userID
//             }
//           })
//           .then((response) => {
//             //Talk to Harry Potter:
//             if (userID === "kQWjnegLN6awZ1p0") {
//               chats = [...chats, {"userid":userID, "username":"Harry Potter", "useravatar":null, "lastMessage":lastMessage, "lastMessageTime":parsingDate(lastMessageTime)}];
//             } else {
//               chats = [...chats, {"userid":userID, "username":(response.data).username, "useravatar":(response.data).avatar, "lastMessage":lastMessage, "lastMessageTime":parsingDate(lastMessageTime)}];
//             }
//             if (i === chatLength - 1) {
//               setChats(chats)
//             }
//           })
//           .catch((error) => {
//             console.log(error);
//             chats = [...chats, {"userid":userID, "username":userID, "useravatar":null, "lastMessage":"error getting", "lastMessageTime":"error"}];
//             if (i === chatLength - 1) setChats(chats)
//           })
//         })
//         .catch((error) => {
//           console.log(error);
//           chats = [...chats, {"userid":userID, "username":userID, "useravatar":null, "lastMessage":"error getting", "lastMessageTime":"error"}];
//           if (i === chatLength - 1) setChats(chats)
//         });
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//     });
//     */

// //AI Chat section code below:
// // User IDs for the new chats
// const newChatUserIDs = ["6864", "6812"];
// console.log("newChatUserIDs:", newChatUserIDs);	 

// newChatUserIDs.forEach((userID) => {
//   let data = JSON.stringify({
//     "userid": auth.userid,
//     "session": auth.session,
//     "to_userid": userID,
//     "limit": 1
//   });

//   let config = {
//     method: 'post',
//     maxBodyLength: Infinity,
//     url: `${process.env.REACT_APP_API_URL}api/message/get`,
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: data,
//   };

//   axios.request(config)
//   .then((response) => {
//     if (response.data[0]) {
//       let lastMessage = (response.data[0]).content;
//       let lastMessageTime = (response.data[0]).send_timestamp;
//       axios.get(`${process.env.REACT_APP_API_URL}api/user/get`, {
//         params: {
//           "userid": userID
//         }
//       })
//       .then((response) => {
//         chats = [...chats, {"userid":userID, "username":(response.data).username, "useravatar":(response.data).avatar, "lastMessage":lastMessage, "lastMessageTime":parsingDate(lastMessageTime)}];
//         setChats(chats);
//       })
//       .catch((error) => {
//         console.log(error);
//         chats = [...chats, {"userid":userID, "username":userID, "useravatar":null, "lastMessage":"error getting", "lastMessageTime":"error"}];
//         setChats(chats);
//       });
//     }
//    })	  
//    .catch((error) => {//catch statement after then
//       console.log(error);
//       chats = [...chats, {"userid":userID, "username":userID, "useravatar":null, "lastMessage":"error getting", "lastMessageTime":"error"}];
//       setChats(chats);
//     });
//   });
// }, [auth, newChatUserIDs, chats]); // This effect will run again if the auth context changes


//   const gridContainerStyle = {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(3, 1fr)',  // Adjust number of columns as necessary
//     gridGap: '20px',  // Space between the grid items
//     padding: '20px',  // Padding around the grid
//   };

//   const chatBoxContainerStyle = {
//     backgroundColor: '#f0f0f0',  // Light background color for each box
//     border: '2px solid #ccc',  // Border for each box
//     padding: '10px',  // Padding inside each box
//     textAlign: 'center',  // Centering text and button
//   };

//   const buttonStyle = {
//     fontSize: '16px',
//     padding: '10px 20px',
//     backgroundColor: 'orange',
//     color: 'white',
//     borderRadius: '5px',
//     width: '100%',  // Ensures the button stretches to the container width
//     cursor: 'pointer',  // Cursor pointer to indicate clickable
//     transition: 'background-color 0.3s',  // Smooth background color transition
//   };

//   const users = newChatUserIDs.map((id, index) => ({
//     id,
//     name: newChatUsernames[index],
//     encodeid: newEncodedID[index]
//   }));


//   return (
//   <div>
    // <div className={styles.headerContainer}>
    //   <h1 className={styles.header}>
    //     Chats for {auth.userid}
    //   </h1>
    // </div>
    // <div className={styles.chatsContainer}>
    //   {chats.map((chat, index) => (
    //     <ChatEntry key={index} chat={chat}/>
    //   ))}
    // </div>
//     {/* Add the chat boxes and send buttons here */}

//     <div style={gridContainerStyle}>
//       {users.map((user) => (
//         <div key={user.id} style={chatBoxContainerStyle}>
//           <h2>Chat for {user.id}</h2>
//           <button style={buttonStyle} onClick={() => navigate('/chat/'+ user.encodeid)}>
//             {user.name}
//           </button>
//         </div>
//       ))}
//     </div>
  
//     <div style={{ textAlign: 'center', marginTop: '16px' }}>
//         <button
//           style={buttonStyle}
//           onClick={() => navigate('/randomchatroom')}
//         >
//           Go to Random Chat Room
//         </button>
//     </div>
//   </div>
  
//   );

// };

// // const handleNavigate = (userid) => {
// //   const [message, setMessage] = useState('');

// //   const sendMessage = () => {
// //     // Here you can integrate with sending message logic or navigation if needed
// //     console.log("Message to send:", message);
// //     // Reset the input after sending
// //     setMessage('');
// //     // If you need to navigate on send, call handleNavigate here
// //     // handleNavigate(userid); // Uncomment and pass userid if necessary
// //   };

// //   return (
// //     <div style={{ width: '100%', maxWidth: '880px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', padding: '10px', boxSizing: 'border-box' }}>
// //       <div style={{ width: '100%', height: '500px', backgroundColor: 'white', border: '1px solid #ccc', marginBottom: '10px', overflowY: 'auto' }}>
// //         {/* Chat messages would go here */}
// //       </div>
// //       <input
// //         type="text"
// //         value={message}
// //         onChange={(e) => setMessage(e.target.value)}
// //         style={{ width: 'calc(100% - 90px)', padding: '10px', marginRight: '10px', boxSizing: 'border-box', float: 'left' }}
// //         placeholder="Type your message here..."
// //       />
// //       <button
// //         onClick={sendMessage}
// //         style={{ width: '80px', padding: '10px 20px', backgroundColor: '#007BFF', color: 'white', textAlign: 'center', border: 'none', cursor: 'pointer' }}
// //       >
// //         Send
// //       </button>
// //       <div style={{ clear: 'both' }}></div>
// //     </div>
// //   );
// // }

// export default ListChat;



import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const ListChat = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  // const users = [
  //   { id: "6877", name: "UmiUni_AI_GLM4", encodeid: "9OjoQeZV9vepZVg8" },
  //   { id: "6866", name: "Paper_AI", encodeid: "oYq9wdL8VgdjPXgE" },
  // ];

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',  // Adjust number of columns as necessary
    gridGap: '20px',  // Space between the grid items
    padding: '20px',  // Padding around the grid
  };

  // const chatBoxContainerStyle = {
  //   backgroundColor: '#f0f0f0',  // Light background color for each box
  //   border: '2px solid #ccc',  // Border for each box
  //   padding: '10px',  // Padding inside each box
  //   textAlign: 'center',  // Centering text and button
  // };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',  // Space between the buttons
    marginTop: '16px',
  };
  const buttonStyle = {
    fontSize: '16px',
    padding: '0',  // Reset padding to 0
    backgroundColor: 'orange',
    color: 'white',
    borderRadius: '5px',
    width: '100px',  // Set fixed width
    height: '100px',  // Set fixed height to make it square
    display: 'flex',  // Use flexbox for centering text
    justifyContent: 'center',  // Center text horizontally
    alignItems: 'center',  // Center text vertically
    cursor: 'pointer',  // Cursor pointer to indicate clickable
    transition: 'background-color 0.3s',  // Smooth background color transition
    marginTop: '10px',  // Space between buttons
};



  return (
    <div>
      <div className={styles.headerContainer}>
        <h1 className={styles.header}>
          Chats for {auth.userid}
        </h1>
      </div >
      {/* <div className={styles.chatsContainer}>
        {chats.map((chat, index) => (
          <ChatEntry key={index} chat={chat}/>
        ))}
      </div> */}
      <div style={buttonContainerStyle}>
        <button
          style={buttonStyle}
          onClick={() => navigate('/chat/ai')}
        >
          AI Chatroom
        </button>
        <button
          style={buttonStyle}
          onClick={() => navigate('/chat/individual')}
        >
          Individual Chatroom
        </button>
        <button
          style={buttonStyle}
          onClick={() => navigate('../randomchatroom')}
        >
          Global Chatroom
        </button>
      </div>
    </div>
  );
};

export default ListChat;
