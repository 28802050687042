import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemCard from "./components/item_cards/Item_card";
import style from './layout.module.css';
import axios from "axios";
import ContentSharing from "./components/content_sharing/content_sharing";
import { IoMdMenu } from 'react-icons/io';


const Layout = () => {
  let schoolId;
  const storedSchool = JSON.parse(localStorage.getItem("savedSchool"));

  if (storedSchool === null) {
    schoolId = 1;
  }
  else {
    schoolId = storedSchool.id;
  }

  const [returnDataItems, setReturnDataItems] = useState({});

  const [usedCards, setUsedCards] = useState(null);
  const [houseCards, setHouseCards] = useState(null);
  const [carCards, setCarCards] = useState(null);
  const [eventCards, setEventCards] = useState(null);
  const [merchantCards, setMerchantCards] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  
  const navigate = useNavigate();

  let itemTypes = ["merchant_house", "used", "house", "car", "event"];

  const usedItemOptions = [
    { value: "books", label: "Books" },
    { value: "Materials", label: "Materials" },
    { value: "Electronics", label: "Electronics" },
    { value: "Instruments", label: "Instruments" },
    { value: "Accessories", label: "Accessories" },
    { value: "Clothing", label: "Clothing" },
    { value: "Sporting", label: "Sporting" },
    { value: "Misc", label: "Misc" }
  ];
  const rentalOptions = [
    { value: "Longterm", label: "Long term" },
    { value: "Shortterm", label: "Short term" },
    { value: "Oncampus", label: "On campus" },
    { value: "Offcampus", label: "Off campus" },
    { value: "Roommates", label: "Roommates" }
  ];
  const carOptions = [
    { value: "Electric", label: "Electrics" },
    { value: "Coupe", label: "Coupes" },
    { value: "Sedan", label: "Sedans" },
    { value: "Luxury", label: "Luxuries" },
    { value: "Suv", label: "SUVs" },
    { value: "Pickup", label: "Pickups" },
    { value: "Carother", label: "Other tools" }
  ];

  useEffect(() => {
    const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, [])

  useEffect(() => {
    const fetchDataItems = async () => {
      let items = {};

      for (let i = 0; i < itemTypes.length; i++) {
        let urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=12&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;

      if (itemTypes[i] === "merchant_house") {
        urlValue = `${process.env.REACT_APP_API_URL}api/item/list?limit=18&offset=0&school_id=${schoolId || 1}&sort=time_desc&type=${itemTypes[i]}`;
      }

        const config = {
          method: "get",
          url: urlValue,
          headers: {
            "Content-Type": "application/json",
          },
          data: {},
        };

        try {
          const response = await axios(config);
          items[itemTypes[i]] = response.data.items;
        } catch (error) {
          console.log(error);
        }
      }

      setReturnDataItems(items);
    };

    fetchDataItems();
  }, [schoolId]);

  useEffect(() => {
    if (Object.keys(returnDataItems).length === itemTypes.length) {
      createCards();
    }
  }, [returnDataItems]);

  const detailNavigation = (iid) => {
    return "/detail/" + iid;
  };

  const makeItemCards = (items) => {

    let src = "https://oss.umiuni.com/";

    return items.map((currentItem, i) => (
      <ItemCard
        item = {currentItem}
        imageUrl = {src + currentItem.photo}
        onClick = {() =>
          window.location.href = detailNavigation(currentItem.iid)
        }
      />
    ));
  }

  const createCards = () => {

    console.log(returnDataItems);

    Object.keys(returnDataItems).forEach((itemType) => {
      const items = returnDataItems[itemType];
      const itemCards = makeItemCards(items);

      switch(itemType) {
        case "used":
          setUsedCards(itemCards);
          break;
        case "house":
          setHouseCards(itemCards);
          break;
        case "car":
          setCarCards(itemCards);
          break;
        case "event":
          setEventCards(itemCards);
          break;
        case "merchant_house":
          setMerchantCards(itemCards);
          break;
      }
    })

  };

  const redirectSearch = (type) => {
    switch(type) {
      case "books": 
        navigate("/search/used/书/0");
        break;
      case "Materials": 
        navigate("/search/used/学习用具/0");
        break;
      case "Electronics":
        navigate("/search/used/电子数码/0");
        break;
      case "Instruments":
        navigate("/search/used/乐器/0");
        break;
      case "Accessories":
        navigate("/search/used/包包及配饰/0");
        break;
      case "Clothing":
        navigate("/search/used/衣服鞋帽/0");
        break;
      case "Sporting":
        navigate("/search/used/运动器材/0");
        break;
      case "Misc":
        navigate("/search/used/其他/0");
        break;
      case "Moreused":
        navigate("search/used/0");
        break;
      case "Longterm":
        navigate("/search/house/长租/0");
        break;
      case "Shortterm":
        navigate("/search/house/短租/0");
        break;
      case "Oncampus":
        navigate("/search/house/校内/0");
        break;
      case "Offcampus":
        navigate("/search/house/校外/0");
        break;
      case "Roommates":
        navigate("/search/house/找室友/0");
        break;
      case "Morerent":
        navigate("/search/house/0");
        break;
      case "Electric":
        navigate("/search/car/电动车/0");
        break;
      case "Coupe":
        navigate("/search/car/coupe/0");
        break;
      case "Sedan":
        navigate("/search/car/轿车/0");
        break;
      case "Luxury":
        navigate("/search/car/超豪华车/0");
        break;
      case "Suv":
        navigate("/search/car/越野车/0");
        break;
      case "Pickup":
        navigate("/search/car/皮卡/0");
        break;
      case "Carother":
        navigate("/search/car/改装配件及工具/0");
        break;
      case "Morecar":
        navigate("/search/car/0");
        break;
      case "Moreevent":
        navigate("/search/event/0");
        break;
      case "Moreshop":
        navigate("/search/merchant_house/0");
        break;
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownOptionClick = (optionValue) => {
    setSelectedOption(optionValue);
    setIsOpen(false);
    redirectSearch(optionValue);
  };

const renderDropdownOptions = (options) => {
  return (
    <div className={style.dropdown}>
      <button onClick={toggleDropdown}><IoMdMenu /></button>
      <div className={`${style.dropdownContent} ${isOpen ? style.show : null}`}>
        {options.map(option => (
          <div key={option.value} onClick={() => handleDropdownOptionClick(option.value)}>
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

  return (
    <Fragment>
      <div className = {style.homepageContainer}>
        <div>
        <div className={style.categoryTitle}>
                <div className={style.title}>
                  Content Sharing
                </div>
                <div className={style.moreItemsLinks} onClick={() => navigate('./content_sharing/upload')}>
                  More
                </div>
            </div>
          <div className={style.cardsContainer}>
            <ContentSharing />
          </div>
        <div className = {style.categoryTitle}>
            <div className = {style.title}>
              Shops
            </div>
            <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Moreshop")}>
                More
            </div>
          </div>
          <div className = {style.cardsContainer}>
            {merchantCards}
          </div>
          <div className = {style.categoryTitle}>
            <div className = {style.title}>
              Used Items
            </div>
            {isMobile ? (
              <>
                {renderDropdownOptions(usedItemOptions)}
              </>
            ) : (
              <>
                <div className={style.hyperLinks} onClick={() => redirectSearch("books")}>Books</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Materials")}>Materials</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Electronics")}>Electronics</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Instruments")}>Instruments</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Accessories")}>Accessories</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Clothing")}>Clothing</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Sporting")}>Sporting</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Misc")}>Misc</div>
              </>
            )}
            <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Moreused")}>
                More
            </div>
          </div>
          <div className = {style.cardsContainer}>
            {usedCards}
          </div>
          <div className = {style.categoryTitle}>
            <div className = {style.title}>
              Rentals
            </div>
            {isMobile ? (
              <>
                {renderDropdownOptions(rentalOptions)}
              </>
            ) : (
              <>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Longterm")}>Long term</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Shortterm")}>Short term</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Oncampus")}>On campus</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Offcampus")}>Off campus</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Roommates")}>Roommates</div>
              </>
            )}
            <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Morerent")}>
                More
            </div>
          </div>
          <div className = {style.cardsContainer}>
            {houseCards}
          </div>
          <div className = {style.categoryTitle}>
            <div className = {style.title}>
              Events
            </div>
            <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Moreevent")}>
                More
            </div>
          </div>
          <div className = {style.cardsContainer}>
            {eventCards}
          </div>
          <div className = {style.categoryTitle}>
            <div className = {style.title}>
              Cars
            </div>
            {isMobile ? (
              <>
                {renderDropdownOptions(carOptions)}
              </>
            ) : (
              <>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Electric")}>Electrics</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Coupe")}>Coupes</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Sedan")}>Sedans</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Luxury")}>Luxuries</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Suv")}>SUVs</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Pickup")}>Pickups</div>
                <div className={style.hyperLinks} onClick={() => redirectSearch("Carother")}>Other tools</div>
              </>
            )}
            <div className = {style.moreItemsLinks} onClick = {() => redirectSearch("Morecar")}>
                More
            </div>
          </div>
          <div className = {style.cardsContainer}>
            {carCards}
          </div> 
        </div>
      </div>  
    </Fragment>
  );
};

export default Layout;

